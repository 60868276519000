var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.leaving, "max-width": "500" },
      scopedSlots: _vm._u(
        [
          _vm.listItem === false
            ? {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "secondary--text",
                          attrs: { small: "", text: "" },
                        },
                        on
                      ),
                      [
                        _c("v-icon", { attrs: { small: "", left: "" } }, [
                          _vm._v("exit_to_app"),
                        ]),
                        _vm._v(" " + _vm._s(_vm.levelLeavingTitle) + " "),
                      ],
                      1
                    ),
                  ]
                },
              }
            : {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-list-item",
                      _vm._g(
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialog = true
                            },
                          },
                        },
                        on
                      ),
                      [
                        _c("v-list-item-title", [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "secondary",
                                    left: "",
                                    small: "",
                                  },
                                },
                                [_vm._v("exit_to_app")]
                              ),
                              _vm._v(" " + _vm._s(_vm.levelLeavingTitle) + " "),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center secondary--text" },
              [
                _c("v-icon", { attrs: { left: "" } }, [_vm._v("exit_to_app")]),
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.levelLeavingTitle)),
                ]),
              ],
              1
            ),
          ]),
          _c("v-spacer"),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "mt-1" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "mb-0 mt-3",
                  attrs: { prominent: "", outlined: "", type: "warning" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column" },
                    [
                      _c("span", { staticClass: "font-weight-bold mb-2" }, [
                        _vm._v("Access loss"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.warningText) + " "),
                      _c("v-divider", {
                        staticClass: "mt-4 secondary",
                        staticStyle: { opacity: "0.22" },
                      }),
                      _c("v-checkbox", {
                        attrs: { color: "secondary" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "secondary--text" }, [
                                  _vm._v("I understand"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.agreeBox,
                          callback: function ($$v) {
                            _vm.agreeBox = $$v
                          },
                          expression: "agreeBox",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.leaving,
                    color: "secondary",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.leaving,
                    disabled: !_vm.agreeBox,
                    color: "error",
                    text: "",
                  },
                  on: { click: _vm.leave },
                },
                [_vm._v("leave")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }